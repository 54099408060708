<template>
  <div>
    <div v-if="Object.keys(errorsValidaion).length">
      <template v-for="(item, key, index) in errorsValidaion">
        <vs-alert
          :key="index+key"
          :title="key"
          color="rgb(231, 154, 23)"
          active="true"
        >
          <template v-for="(error,index) in item">
            <div :key="'eee'+index+key">{{ error }}</div>
          </template>
        </vs-alert>
      </template>
    </div>
    <ScoreManagmentForm
      :Score.sync="Score"
      :is-update="false"
      :loading.sync="loading"
      v-on:store="onStroe"
    />
  </div>
</template>

<script>
//  npx eslint --fix src/*.ts
import ScoreManagmentForm from '../components/ScoreManagmentForm'
import { RepositoryFactory } from '@/Repositories/RepositoryFactory'
const ScoreManager = RepositoryFactory.get('ScoreManager', 'ScoreManager')

export default {
  name: 'Create2',
  components: {
    ScoreManagmentForm
  },
  created () {
    this.init()
  },

  data () {
    return {
      addAgain: true,
      loading: false,
      errorsValidaion: {},
      Score: {
        school_id: null,
        division_id: null,
        basic: [
          //start array
          {
            name: 'school_director',
            status: true,
            point: 100
          },
          {
            name: 'division_principal',
            status: false,
            point: 0
          },
          {
            name: 'division_supervisor',
            status: false,
            point: 0
          },
          {
            name: 'division_assessor',
            status: false,
            point: 0
          },
          {
            name: 'other',
            status: false,
            point: 0
          }
        ], // end array
        commun: [
          //start array
          {
            name: 'academic_qualities',
            status: false,
            point: 0
          },
          {
            name: 'candidate_enthusiasm',
            status: false,
            point: 0
          },
          {
            name: 'candidate_sports_skills',
            status: false,
            point: 0
          },
          {
            name: 'educational_background',
            status: false,
            point: 0
          },
          {
            name: 'leadership_skills',
            status: false,
            point: 0
          },
          {
            name: 'parents_culture_fit',
            status: false,
            point: 0
          },
          {
            name: 'distance_from_home',
            status: false,
            point: 0
          }
        ], // end array

        criterias: []
      }
    }
  },
  methods: {
    onStroe (Score) {
      this.loading = true
      this.$vs.loading()
      if (this.addAgain) {
        ScoreManager.create(Score)
          .then((res) => {
            this.addAgain = false
            this.$router.push({ name: 'scoreManager.list' })
            this.handlerCreate(res)
          })
          .catch((err) => this.handelCatch(err))
      }
    },
    handelCatch (err) {
      this.loading = false
      this.$vs.loading.close()
    },
    init () { },
    handlerCreate (res) {
      if (res.key == 1) {
        // sucess
        this.handleSuceessCreate(res)
      } else {
        this.handelErrorCreate(res)
      }
      this.loading = false
      this.$vs.loading.close()
    },
    handleSuceessCreate (res) {
      // sucess
      this.$vs.notify({
        color: 'success',
        title: this.$t('basic.create'),
        text: res.msg
      })
      this.resetInput()
    },
    handelErrorCreate (res) {
      this.errorsValidaion = res.errors
      const obj = res.errors
      this.$vs.notify({
        color: 'danger',
        title: this.$t('basic.error_validation'),
        text: obj[Object.keys(obj)[0]][0]
      })
    },
    resetInput () {
      this.Score = {
        school_id: null,
        division_id: null,
        basic: [
          //start array
          {
            name: 'school_director',
            status: true,
            point: 100
          },
          {
            name: 'division_principal',
            status: false,
            point: 0
          },
          {
            name: 'division_supervisor',
            status: false,
            point: 0
          },
          {
            name: 'division_assessor',
            status: false,
            point: 0
          },
          {
            name: 'other',
            status: false,
            point: 0
          }
        ], // end array
        commun: [
          //start array
          {
            name: 'academic_qualities',
            status: false,
            point: 0
          },
          {
            name: 'candidate_enthusiasm',
            status: false,
            point: 0
          },
          {
            name: 'candidate_sports_skills',
            status: false,
            point: 0
          },
          {
            name: 'educational_background',
            status: false,
            point: 0
          },
          {
            name: 'leadership_skills',
            status: false,
            point: 0
          },
          {
            name: 'parents_culture_fit',
            status: false,
            point: 0
          },
          {
            name: 'distance_from_home',
            status: false,
            point: 0
          }
        ], // end array

        criterias: []
      }
      this.errorsValidaion = {}
    }
  }
}
</script>

<style scoped>
</style>
